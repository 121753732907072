import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Sketch from './sketch';

import sketch from '../scripts/phi';

const Header = ({ siteTitle }) => (
  <header
    style={{
    }}
  >
    <div id="spriteField"
      style={{
        margin: `0 auto`,
        width: `100%`,
        minHeight: `200px`,
        padding: `1.45rem 1.0875rem`,
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `flex-end`
      }}
    >  
    <Sketch sketch={sketch} /> 
      <h1 style={{ 
        margin: `auto`, 
        textAlign: `center`, 
        verticalAlign:`bottom`,
        width: `auto`,
        display: `inline-block`,
        // fontSize: `1.6rem`
      }}>
        <Link
          to="/"
          style={{
            width: `100%`,
            color: `white`,
            textDecoration: `none`,
            letterSpacing: `0.6rem`,
            fontSize: '2em'
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <div className={`linkContainer`} 
        style={{
          width: "70%", 
          minWidth: '80vw', 
          margin:'auto', 
          display: 'flex', 
          flexDirection:'row', 
          justifyContent:'space-between'
        }}
      >
        <Link to="/">HOME</Link>
        <Link to="/about">ABOUT</Link>
        <Link to="/links">LINKS</Link>
        <Link to="/contact">CONTACT</Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
