/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
// import BackgroundImage from 'gatsby-background-image'

import Header from "./header"
import "./layout.css"

import SoundCloudSVG from "../svgs/soundcloudSVG"
import BandCampSVG from "../svgs/bandcampSVG"
import SpotifySVG from "../svgs/spotifySVG"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
    <div style={{overflowY:`hidden`,}} >
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="bodyDiv"
        style={{
          margin: `0 auto`,
          maxWidth: `100%`,
          padding: `1.0875rem 1.0875rem 1.45rem`,
          color: `#A8AAAFFF`,
          display: `flex`,
          justifyContent: `flex-start`,
          flexDirection: `column`,
          height: `calc(100vh - 200px)`,
          overflowY:`hidden`,
          // minHeight: `600px`,
          alignItems: `center`,
          backgroundSize: `cover`,
          backgroundImage:`linear-gradient(to bottom, black, transparent 260%), url('${`/../cropped cover.png`}')`
        }}
      >

        <main>{children}</main>
        
        <footer
          style={{
            position: `absolute`,
            bottom: `0`,
            height: `4rem`,
            textAlign:`center`,
            margin: `auto auto`,
            left: `auto`,
            right: `auto`,
            color: `#A8AAAFFF`,
            backgroundColor: `rgb(0,0,0,0)`,
            width: `100%`
          }}
        >
          {/*<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '200px', margin: '0 auto 20px auto'}}>
                            <a href="https://soundcloud.com/the-steech" target="_blank" rel="noopener noreferrer" style={{margin: '0 auto'}}>
                               <div style={{display: 'flex', flexDirection:'row', justifyContent: 'flex-start', color: 'white'}}>
                                <SoundCloudSVG/>
                               </div>
                            </a>
          
          
                            <a href="https://thesteech.bandcamp.com/" target="_blank" rel="noopener noreferrer" style={{margin: '0 auto'}}>
                               <div style={{display: 'flex', flexDirection:'row', justifyContent: 'flex-start', color: 'white'}}>
                                  <BandCampSVG/>
                               </div>
                            </a>
          
                            <a href="" target="_blank" rel="noopener noreferrer" style={{margin: '0 auto'}}>
                               <div style={{display: 'flex', flexDirection:'row', justifyContent: 'flex-start', color: 'white'}}>
                                  <SpotifySVG/>
                               </div>
                            </a>
                    </div>*/}
          <div>© {new Date().getFullYear()} THE STEECH.</div>
        </footer>

      </div>
    </div>

    </>
  )
}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
